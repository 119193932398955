import { Injectable } from '@angular/core';
import {
  CreateHotToastRef,
  HotToastService,
  ToastOptions
} from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  readonly options: ToastOptions<unknown> = {
    id: 'liv-toast',
    duration: 4000
  };
  constructor(private hotToastService: HotToastService) {}

  success(
    message: string,
    options?: ToastOptions<unknown>
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.success(message, {
      ...this.options,
      ...options,
      className: 'custom-toast-success'
    });
  }

  error(
    message: string,
    options?: ToastOptions<unknown>
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.error(message, {
      ...this.options,
      ...options,
      className: 'custom-toast-error'
    });
  }

  info(
    message: string,
    options?: ToastOptions<unknown>
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.info(message, {
      ...this.options,
      ...options,
      className: 'custom-toast-info'
    });
  }

  warn(
    message: string,
    options?: ToastOptions<unknown>
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.warning(message, {
      ...this.options,
      ...options,
      className: 'custom-toast-warning'
    });
  }
}
